import { generateBooleanSchema, generateRootObjectSchema, generateStringSchema, generateUrlSchema, generateDateTimeSchema, generateColorSchema, generateArraySchema } from "components/cards/common/schema";
import { BaseAtomSpec, generateAtomContainerSchema } from "components/cards/atoms/BaseAtomSpec";
import { TextParticleSpec, ImageParticleSpec, ButtonParticleSpec } from "components/cards/particles/YinzCam";

const teamTextSpecs = { size: '1.5rem', style: 'normal', weight: '400', color: "#000" };

export const specification = new BaseAtomSpec("Box Score", generateRootObjectSchema({
    container: generateAtomContainerSchema(),
    overlayPadding: generateStringSchema("Overlay Padding", "The padding (inset) of the overlay text/buttons relative to the background image.", {
        defaultValue: '3rem'
    }),
    containerBackgroundColor: generateColorSchema("Background Color", "Select a background color"),
    matchContentColor: generateColorSchema("Match Content Color", "Select a color for outer shape"),
    matchStatus: generateStringSchema("Match Status", "Select the present status of the match", {
        choices: ['pre', 'live', 'post'],
        choiceTitles: ['Pre', 'Live', 'Post']
    }),
    imageHomeTeam: ImageParticleSpec.generateSchema("Home Team Logo", "Upload Home Team logo", {
        defaultValues: {
            sizing: 'contain', source: 'https://resources-uk.yinzcam.com/soccer/shared/logos/_default.svg',
            container: { height: "9rem", width: "9rem" }
        }
    }),
    textHomeTeam: TextParticleSpec.generateSchema("Home Team Abbr.", "Enter home team abbreviation", {
        defaultValues: teamTextSpecs
    }),
    imageAwayTeam: ImageParticleSpec.generateSchema("Away Team Logo", "Upload Away Team logo", {
        defaultValues: {
            sizing: 'contain', source: 'https://resources-uk.yinzcam.com/soccer/shared/logos/_default.svg',
            container: { height: "9rem", width: "9rem" }
        }
    }),
    textAwayTeam: TextParticleSpec.generateSchema("Away Team Abbr.", "Enter away team abbreviation", {
        defaultValues: teamTextSpecs
    }),
    btnCupName: ButtonParticleSpec.generateSchema("Competition Name", "Enter competition name", {
        defaultValues: { text: { size: '1.5rem', weight: '400', transform: 'uppercase' }, image: { maxHeight: '3rem' } }//container: { height: '2rem', width: '10rem' }
    }),
    matchDateTime: generateDateTimeSchema("Match Date Time", "Enter date and time of match"),
    textMatchDateTime: TextParticleSpec.generateSchema("Match Date Time", "Styling for the match date and time text.", {
        defaultValues: { size: '2.25rem', weight: '700' },
        uneditableFields: { text: true }
    }),
    textStadiumName: TextParticleSpec.generateSchema("Venue Name", "Enter venue name", {
        defaultValues: { size: '1.5rem', weight: '400', transform: 'uppercase', color: '#777576' }
    }),
    sponsorBackgroundColor: generateColorSchema("Sponsor Background", "Specify a color for sponsor background",
    ),
    sponsorInfo: TextParticleSpec.generateSchema("Sponsor Name", "Enter sponsor name here", {
        defaultValues: { size: '1.25rem', weight: '700', transform: 'uppercase', color: "#000", container: { padding: '0.75rem 0' } }
    }),
    btnActions: generateArraySchema("Buttons List", "Enter the names of the buttons",
        ButtonParticleSpec.generateSchema("CTA Button", "Define CTA Button", {
            defaultValues: {
                text: { size: '1.5rem', weight: '700', transform: 'uppercase' },
                container: { padding: '0.5rem 1rem' },
                image: { container: { height: '2.25rem', width: "2.25rem" } },
                gap: '0.5rem',
            }
        })
    ),
    textHomeScore: TextParticleSpec.generateSchema("Home Team Score", "Enter home team score", {
        defaultValues: { size: '4.5rem', weight: "900", color: "#000" }
    }),
    textAwayScore: TextParticleSpec.generateSchema("Away Team Score", "Enter home team score", {
        defaultValues: { size: '4.5rem', weight: "900", color: "#000" }
    }),
    textMatchMinutes: TextParticleSpec.generateSchema("Match Minutes", "Enter match minutes", {
        defaultValues: { size: '2.25rem', weight: '400', color: "var(--theme-mode-background-color)" } 
    }),
    txtLive: TextParticleSpec.generateSchema("Live Match Text", "Default text is `LIVE` when match status is live", {
        defaultValues: {
            size: "1.75rem",
            weight: "900",
            text: "LIVE",
        }
    }),
    txtFinal: TextParticleSpec.generateSchema("Final Text", "Default text is `FINAL` when match status is post", {
        defaultValues: {
            color: "#0054a5",
            size: "2.25rem",
            weight: "400",
            text: "FINAL",
        }
    }),
    txtBroadcasters: TextParticleSpec.generateSchema("Broadcasters Text", "The text to display ahead of the Broadcasters list.", {
        defaultValues: {
            size: "1rem",
            weight: "600",
            text: "Watch On",
        }
    }),
    btnBroadcasters: generateArraySchema("Broadcaster Buttons", "A list of broadcaster buttons.",
        ButtonParticleSpec.generateSchema("Broadcaster Button", "A broadcaster button", {
            defaultValues: {
                showText: false,
                text: { size: '1.5rem', weight: '700', wrap: "wrap" },
                image: { container: { height: '2.25rem' } },
                gap: '0.5rem',
            }
        })
    ),
}));

