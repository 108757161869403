import { generateBooleanSchema, generateColorSchema, generateRootObjectSchema, generateStringSchema, generateUrlSchema } from "components/cards/common/schema";
import { BaseAtomSpec, generateAtomContainerSchema } from "components/cards/atoms/BaseAtomSpec";
import { TextParticleSpec, ImageParticleSpec, ButtonParticleSpec, RichTextParticle, RichTextParticleSpec, ButtonParticle } from "components/cards/particles/YinzCam";

const textDefaultValues = { defaultValues: { size: "1.25rem", weight: "700" } };

export const specification = new BaseAtomSpec("Player Bio Card", generateRootObjectSchema({
    container: generateAtomContainerSchema(),
    overlayPadding: generateStringSchema("Overlay Padding", "The padding (inset) of the overlay text/buttons relative to the background image.", {
        defaultValue: '3rem'
    }),
    txtFieldNames: TextParticleSpec.generateSchema("Field Name", "Field Name", {
        defaultValues: { size: "1.25rem", weight: "400" }
    }),
    txtPlayerFullName: TextParticleSpec.generateSchema("Player Name", "Player Name", {
        defaultValues: { size: "2.5rem", weight: "900" }
    }),
    txtPlayerName: TextParticleSpec.generateSchema("Player Name", "Player Name", {
        defaultValues: { size: "2.5rem", weight: "900" }
    }),
    txtDOB: TextParticleSpec.generateSchema("Date of Birth", "Date of Birth", {
        ...textDefaultValues
    }),
    txtPOB: TextParticleSpec.generateSchema("Place of Birth", "Place of Borth", { ...textDefaultValues }),
    txtHeight: TextParticleSpec.generateSchema("Height", "Height", { ...textDefaultValues }),
    txtWeight: TextParticleSpec.generateSchema("Weight", "Weight", { ...textDefaultValues }),
    txtSummary: RichTextParticleSpec.generateSchema("Summary", "Summary", {
        defaultValues: { textDefaults: { size: "1.25rem", weight: "400" } }
    }),
    playerNameHidden: generateBooleanSchema("Player Name Hidden", "Whether player name are hidden.", {
        defaultValue: false,
    }),
    playerStatsHidden: generateBooleanSchema("Player Stats Hidden", "Whether player stats are hidden.", {
        defaultValue: false,
    }),
    playerBioHidden: generateBooleanSchema("Player Bio Hidden", "Whether player bio are hidden.", {
        defaultValue: false,
    }),
    btnViewMoreSummary: ButtonParticleSpec.generateSchema("View More Summary", "View More Summary", {
        defaultValues: { showImage: false, text: { size: "1.5rem" } }
    }),
    txtAlternateText: generateStringSchema("Alternate Text", "Alternate Text"),
    gradientColor: generateColorSchema("Gradient Color", "Gradient Color"),
    shouldDisplayViewMore: generateBooleanSchema("Should Display View More", "Should Display View More", {
        defaultValue: false
    }),
    shouldDisplayFullName: generateBooleanSchema("Should Display Full Name?", "Should Display Full Name?", {
        defaultValue: false
    }),
    summaryBoxHeight: generateStringSchema("Summary Box Height", "Summary Box Height")
}));
