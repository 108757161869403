<script context="module" lang="ts">var _a;
export { specification } from "./spec";
</script>

<script lang="ts">var _a;
import "lodash";
import { BaseAtom, getContentManager, } from "components/cards/atoms";
import { ImageParticle, TextParticle, ButtonParticle, } from "components/cards/particles/YinzCam";
import "components/cards/utilities";
import "js/i18n";
import { DateTime } from "luxon";
import "js/url";
export let container = undefined;
export let slug = undefined;
export let link = undefined;
export let publishDateTime;
export let viewTimeDuration;
export let author = undefined;
export let imgUserIcon;
export let publishDateAndDurationText = undefined;
export let txtAuthorInfo = undefined;
export let txtSecondAuthor = undefined;
export let txtShareText = undefined;
export let titleText = undefined;
export let showUserIconIn = undefined;
export let showAuthorIn = undefined;
export let showPublishDateAndDuration = undefined;
export let showSecondAuthorInfo = undefined;
export let showShareStrip = undefined;
export let showTwitter = undefined;
export let showFacebook = undefined;
export let showLine = undefined;
export let showWeibo = undefined;
export let imgTwitter = undefined;
export let imgFacebook = undefined;
export let imgLine = undefined;
export let imgWeibo = undefined;
$: mtc = getContentManager().getMediaTypeConfigurationStore("article", slug, link);
$: shareLink = (_a = $mtc === null || $mtc === void 0 ? void 0 : $mtc.shareLinkUrl) === null || _a === void 0 ? void 0 : _a.href;
let atomWidthPx;
let now = DateTime.fromISO(new Date().toISOString());
$: publishDateTimeDisplay = publishDateTime ? DateTime.fromISO(publishDateTime) : now;
$: [days, hours, mins, seconds] = now
    .diff(publishDateTimeDisplay)
    .toFormat("dd:hh:mm:ss")
    .split(":");
$: viewTimeDurationDisplay =
    parseInt(days) > 0
        ? `on ${publishDateTimeDisplay.toFormat("d LLLL yyyy")}`
        : `${+hours} hours ${+mins} minutes ago`;
$: socialURL = new URL(shareLink, window.location.href);
$: shareUrlFB = `https://www.facebook.com/sharer/sharer.php?u=${socialURL === null || socialURL === void 0 ? void 0 : socialURL.href};src=sdkpreparse`;
$: shareUrlTwitter = `https://x.com/intent/post?text=${titleText === null || titleText === void 0 ? void 0 : titleText.text}+${socialURL === null || socialURL === void 0 ? void 0 : socialURL.href}`;
$: shareUrlLine = `https://social-plugins.line.me/lineit/share?url=${socialURL === null || socialURL === void 0 ? void 0 : socialURL.href}&text=${titleText === null || titleText === void 0 ? void 0 : titleText.text}`;
$: shareUrlWeibo = `https://service.weibo.com/share/share.php?url=${encodeURIComponent(socialURL === null || socialURL === void 0 ? void 0 : socialURL.href)}`;
$: console.log({ shareUrlFB });
</script>

<BaseAtom
    _isotope="ArticleHeaderBAtom"
    {...container}
    bind:_clientWidthPx={atomWidthPx}
>
    <div
        class="article-header-atom"
        class:mobile-width={atomWidthPx <= 450}
        class:tablet-width={atomWidthPx > 450 && atomWidthPx <= 830}
    >
        <div class="icon-author">
            <div class="user-icon">
                {#if showUserIconIn}
                    <ImageParticle {...author.image} _block={true} />
                {/if}
            </div>
            {#if author?.nameText?.text}
                <div class="author-info">
                    {#if showAuthorIn}
                        <TextParticle
                            {...author?.nameText}
                            text={`By ${author?.nameText?.text}`}
                            _block={true}
                        />
                    {/if}
                    {#if showPublishDateAndDuration && viewTimeDurationDisplay}
                        <TextParticle
                            {...publishDateAndDurationText}
                            text={`published ${viewTimeDurationDisplay}`}
                            _block={true}
                        />
                    {/if}
                    {#if showSecondAuthorInfo && author?.positionText?.text}
                        <TextParticle {...author?.positionText} _block={true} />
                    {/if}
                </div>
            {/if}
        </div>
        <div class="share-strip">
            {#if showShareStrip}
                <div class="share-text">
                    <TextParticle {...txtShareText} />
                </div>
                <div class="share-logos">
                    {#if showTwitter}
                        <ButtonParticle
                            {...imgTwitter}
                            image={{
                                source: "https://resources-uk.yinzcam.com/soccer/shared/icon_twitter_x.svg",
                            }}
                            link={shareUrlTwitter}
                        />
                    {/if}
                    {#if showFacebook}
                        <ButtonParticle
                            {...imgFacebook}
                            image={{
                                source: "https://resources-uk.yinzcam.com/soccer/shared/icon_facebook.svg",
                            }}
                            link={shareUrlFB}
                        />
                    {/if}
                    {#if showLine}
                        <ButtonParticle
                            {...imgLine}
                            image={{
                                source: "https://resources-uk.yinzcam.com/soccer/shared/icon_line.svg",
                            }}
                            link={shareUrlLine}
                        />
                    {/if}
                    {#if showWeibo}
                        <ButtonParticle
                            {...imgWeibo}
                            image={{
                                source: "https://resources-uk.yinzcam.com/soccer/shared/icon_weibo.svg",
                            }}
                            link={shareUrlWeibo}
                        />
                    {/if}
                </div>
            {/if}
        </div>
    </div>
</BaseAtom>

<style>
    .article-header-atom {
        width: 100%;
        display: grid;
        grid-template-columns: repeat(auto-fit, minmax(20rem, 1fr));
        justify-items: flex-start;
        align-items: center;
        grid-gap: 1rem;
        gap: 1rem;
    }
    .article-header-atom.tablet-width {
        grid-template-columns: repeat(auto-fit, minmax(15rem, 1fr));
    }
    .article-header-atom.mobile-width .share-strip {
            justify-self: flex-start;
        }
    .author-info {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: flex-start;
        flex-wrap: wrap;
    }
    .author-info > :global(*){
        justify-content: left !important;
    }
    .share-strip {
        justify-self: end;
        display: flex;
        justify-content: space-evenly;
        align-items: center;
        gap: 0.5rem;
    }
    .share-text {
        margin-right: 1rem;
    }
    .share-logos {
        display: flex;
        justify-content: space-evenly;
    }
    .icon-author {
        display: flex;
        justify-content: space-between;
        align-items: center;
    }</style>
