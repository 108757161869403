<script context="module" lang="ts">var _a;
export { specification } from "./spec.ts";
</script>

<script lang="ts">var _a;
import "lodash";
import { BaseAtom } from "components/cards/atoms";
import { TextParticle, ImageParticle, } from "components/cards/particles/YinzCam";
export let container;
export let overlayPadding;
export let txtHeading;
export let matchStats = undefined;
export let txtHomeOnTargetShots;
export let txtAwayOnTargetShots;
export let txtHomeOffTargetShots;
export let txtAwayOffTargetShots;
export let txtHomeOnTargetPercent;
export let txtHomeOffTargetPercent;
export let txtAwayOnTargetPercent;
export let txtAwayOffTargetPercent;
export let txtOnTargetHeading;
export let txtOffTargetHeading;
export let txtHomeTotalShots;
export let txtAwayTotalShots;
export let txtPercentBottom;
export let imgGoalPost;
export let imgHomeLogo;
export let imgAwayLogo;
export let imgFieldImage;
export let myTeamColor;
export let opponentTeamColor;
export let homeTricode;
export let awayTricode;
let atomWidthRem;
let colorHomeTeam = "", colorAwayTeam = "";
$: isMobile = atomWidthRem <= 36;
$: isTablet = atomWidthRem <= 59 && atomWidthRem > 36;
$: totalShots = matchStats === null || matchStats === void 0 ? void 0 : matchStats.find((stat) => (stat === null || stat === void 0 ? void 0 : stat.statName) === "Shots");
$: totalShotsOnTarget = matchStats === null || matchStats === void 0 ? void 0 : matchStats.find((stat) => (stat === null || stat === void 0 ? void 0 : stat.statName) === "Shots On Target");
$: totalShotsOffTarget = {
    homeValue: parseInt(totalShots === null || totalShots === void 0 ? void 0 : totalShots.homeValue) - parseInt(totalShotsOnTarget === null || totalShotsOnTarget === void 0 ? void 0 : totalShotsOnTarget.homeValue),
    awayValue: parseInt(totalShots === null || totalShots === void 0 ? void 0 : totalShots.awayValue) - parseInt(totalShotsOnTarget === null || totalShotsOnTarget === void 0 ? void 0 : totalShotsOnTarget.awayValue),
    maxValue: parseInt(totalShots === null || totalShots === void 0 ? void 0 : totalShots.maxValue) - parseInt(totalShotsOnTarget === null || totalShotsOnTarget === void 0 ? void 0 : totalShotsOnTarget.maxValue),
    statName: "Shots Off Target",
};
$: homeTeamTotalShots = parseInt(totalShots === null || totalShots === void 0 ? void 0 : totalShots.homeValue);
$: awayTeamTotalShots = parseInt(totalShots === null || totalShots === void 0 ? void 0 : totalShots.awayValue);
$: homeTeamOnTargetPercent = Math.round((parseInt(totalShotsOnTarget === null || totalShotsOnTarget === void 0 ? void 0 : totalShotsOnTarget.homeValue) / homeTeamTotalShots) * 100);
// $: homeTeamOffTargetPercent = Math.floor(
//     (parseInt(totalShotsOffTarget?.homeValue) / homeTeamTotalShots) * 100,
// );
$: homeTeamOffTargetPercent = 100 - homeTeamOnTargetPercent;
$: awayTeamOnTargetPercent = Math.round((parseInt(totalShotsOnTarget === null || totalShotsOnTarget === void 0 ? void 0 : totalShotsOnTarget.awayValue) / awayTeamTotalShots) * 100);
// $: awayTeamOffTargetPercent = Math.floor(
//     (parseInt(totalShotsOffTarget?.awayValue) / awayTeamTotalShots) * 100,
// );
$: awayTeamOffTargetPercent = 100 - awayTeamOnTargetPercent;
$: txtGoalsDisplayMultiplier = isMobile
    ? "0.25"
    : isTablet
        ? "0.65"
        : "1.00";
$: if ((homeTricode === null || homeTricode === void 0 ? void 0 : homeTricode.toLowerCase()) === ((_a = CONFIG === null || CONFIG === void 0 ? void 0 : CONFIG.tricode) === null || _a === void 0 ? void 0 : _a.toLowerCase())) {
    colorHomeTeam = myTeamColor;
    colorAwayTeam = opponentTeamColor;
}
else {
    colorHomeTeam = opponentTeamColor;
    colorAwayTeam = myTeamColor;
}
</script>

<BaseAtom
    bind:_clientWidthRem={atomWidthRem}
    _isotope="MCShootingAtom"
    _cssVariablePrefix="mc-shooting"
    {...container}
    {overlayPadding}
    {colorHomeTeam}
    {colorAwayTeam}
>
    <div class="main-container">
        <div class="heading">
            <TextParticle {...txtHeading} />
        </div>
        <div class="goal-post" class:mobile={isMobile}>
            <div class="background-image">
                <ImageParticle {...imgGoalPost} />
            </div>
            <div class="shots-text">
                <div class="home-side-shots">
                    <div class="off-target-shots">
                        <TextParticle
                            {...txtHomeOffTargetShots}
                            size={`calc(${txtHomeOffTargetShots?.size}*${txtGoalsDisplayMultiplier})`}
                            text={`${totalShotsOffTarget?.homeValue || 0}`}
                        />
                    </div>
                    <div class="on-target-shots">
                        <TextParticle
                            {...txtHomeOnTargetShots}
                            size={`calc(${txtHomeOffTargetShots?.size}*${txtGoalsDisplayMultiplier})`}
                            text={`${totalShotsOnTarget?.homeValue || 0}`}
                        />
                    </div>
                </div>
                <div class="away-side-shots">
                    <div class="off-target-shots">
                        <TextParticle
                            {...txtAwayOffTargetShots}
                            size={`calc(${txtHomeOffTargetShots?.size}*${txtGoalsDisplayMultiplier})`}
                            text={`${totalShotsOffTarget?.awayValue || 0}`}
                        />
                    </div>
                    <div class="on-target-shots">
                        <TextParticle
                            {...txtAwayOnTargetShots}
                            size={`calc(${txtHomeOffTargetShots?.size}*${txtGoalsDisplayMultiplier})`}
                            text={`${totalShotsOnTarget?.awayValue || 0}`}
                        />
                    </div>
                </div>
            </div>
        </div>
        <div class="field-image">
            <ImageParticle {...imgFieldImage} />
        </div>
        <div class="combined-shots">
            <ImageParticle {...imgHomeLogo} _block={true} />
            <div class="total-shots-box">
                <div class="shots-heading">
                    <span>Total Shots</span>
                </div>
                <div class="shots-box">
                    <div class="home-goals">
                        <TextParticle
                            {...txtHomeTotalShots}
                            text={`${totalShots?.homeValue || 0}`}
                        />
                    </div>
                    <span>|</span>
                    <div class="away-goals">
                        <TextParticle
                            {...txtAwayTotalShots}
                            text={`${totalShots?.awayValue || 0}`}
                        />
                    </div>
                </div>
            </div>
            <ImageParticle {...imgAwayLogo} _block={true} />
        </div>
        <div class="target-wise-shots">
            <div class="on-target-shots-bottom">
                <div class="home-shot-percent">
                    <TextParticle
                        {...txtHomeOnTargetPercent}
                        text={`(${homeTeamOnTargetPercent || 0}%)`}
                    />
                    <TextParticle
                        {...txtPercentBottom}
                        text={`${totalShotsOnTarget?.homeValue || 0}`}
                    />
                </div>
                <div class="direction">
                    <TextParticle {...txtOnTargetHeading} text={`On Target`} />
                </div>
                <div class="away-shot-percent">
                    <TextParticle
                        {...txtPercentBottom}
                        text={`${totalShotsOnTarget?.awayValue || 0}`}
                    />
                    <TextParticle
                        {...txtAwayOnTargetPercent}
                        text={`(${awayTeamOnTargetPercent || 0}%)`}
                    />
                </div>
            </div>
            <div class="off-target-shots-bottom">
                <div class="home-shot-percent">
                    <TextParticle
                        {...txtHomeOffTargetPercent}
                        text={`(${homeTeamOffTargetPercent || 0}%)`}
                    />
                    <TextParticle
                        {...txtPercentBottom}
                        text={`${totalShotsOffTarget?.homeValue || 0}`}
                    />
                </div>
                <div class="direction">
                    <TextParticle
                        {...txtOffTargetHeading}
                        text={`Off Target`}
                    />
                </div>
                <div class="away-shot-percent">
                    <TextParticle
                        {...txtPercentBottom}
                        text={`${totalShotsOffTarget?.awayValue || 0}`}
                    />
                    <TextParticle
                        {...txtAwayOffTargetPercent}
                        text={`(${awayTeamOffTargetPercent || 0}%)`}
                    />
                </div>
            </div>
        </div>
    </div>
</BaseAtom>

<style>
    .main-container {
        display: flex;
        flex-direction: column;
        padding: var(--mc-shooting-overlay-padding, unset);
        width: 100%;
    }
    .goal-post {
        display: grid;
    }
    .goal-post .background-image {
            z-index: 1;
        }
    .goal-post > * {
        grid-area: 1/1;
    }
    .shots-text {
        display: flex;
    }
    .home-side-shots {
        width: 50%;
        background-color: var(--mc-shooting-color-home-team);
        position: relative;
    }
    .home-side-shots .off-target-shots {
            position: absolute;
            top: 2rem;
            left: 2rem;
        }
    .home-side-shots .on-target-shots {
            position: absolute;
            bottom: 4rem;
            right: 4rem;
        }
    .away-side-shots {
        width: 50%;
        background-color: var(--mc-shooting-color-away-team);
        position: relative;
    }
    .away-side-shots .off-target-shots {
            position: absolute;
            top: 2rem;
            right: 2rem;
        }
    .away-side-shots .on-target-shots {
            position: absolute;
            bottom: 4rem;
            left: 4rem;
        }
    .combined-shots {
        display: flex;
        justify-content: center;
        align-items: center;
        gap: 1rem;
        padding: 1rem 0;
    }
    .total-shots-box {
        border: 1px solid gray;
        border-radius: 0.75rem;
        padding: 0.5rem 0.75rem;
        line-height: 1;
    }
    .shots-heading {
        text-align: center;
    }
    .heading{
        padding: 1rem 0;
    }
    .shots-box {
        display: flex;
        align-items: center;
        justify-content: space-evenly;
        gap: 1.5rem;
        padding: 0 2rem;
    }
    .shots-box span {
            font-size: 1.5rem;
            font-weight: 300;
            color: #dedede;
            text-align: center;
        }
    .target-wise-shots {
        padding: 1rem 0;
    }
    .target-wise-shots .on-target-shots-bottom,
        .target-wise-shots .off-target-shots-bottom {
            display: grid;
            grid-template-columns: 1fr 1fr 1fr;
            align-items: center;
            justify-items: center;
            place-items: center;
            grid-gap: 2rem;
            gap: 2rem;
        }
    .target-wise-shots .on-target-shots-bottom .home-shot-percent,
            .target-wise-shots .off-target-shots-bottom .home-shot-percent,
            .target-wise-shots .on-target-shots-bottom .away-shot-percent,
            .target-wise-shots .off-target-shots-bottom .away-shot-percent {
                display: flex;
                align-items: center;
                gap: 0.5rem;
            }
    .target-wise-shots .on-target-shots-bottom .home-shot-percent, .target-wise-shots .off-target-shots-bottom .home-shot-percent {
                justify-self: end;
            }
    .target-wise-shots .on-target-shots-bottom .away-shot-percent, .target-wise-shots .off-target-shots-bottom .away-shot-percent {
                justify-self: start;
            }

    .goal-post.mobile .shots-text .home-side-shots .off-target-shots {
                    top: 1rem;
                    left: 1rem;
                }

    .goal-post.mobile .shots-text .home-side-shots .on-target-shots {
                    bottom: 2rem;
                    right: 2rem;
                }

    .goal-post.mobile .shots-text .away-side-shots .off-target-shots {
                    right: 1rem;
                    top: 1rem;
                }

    .goal-post.mobile .shots-text .away-side-shots .on-target-shots {
                    bottom: 2rem;
                    left: 2rem;
                }</style>
