<script lang="ts">import { Boundary } from "@crownframework/svelte-error-boundary/src/index";
import { applyImageTransformation, buildInlineStyleFromObject, buildInlineStyleFromObjects, buildThemeModeInlineStyle, getImageTransformationSlug, mergeInlineStyles, rewriteExternalCDNURL } from "components/cards/utilities";
import "./BaseParticleSpec";
import { isAbsoluteUrl, shouldOpenInNewWindow } from "js/url";
import { containerActionsHandler, resizeObserverAction } from "../common/actions";
import { getContext } from "svelte";
import { CardsTabContextKey } from "../common/context";
import _ from "lodash";
import { f7, f7ready } from 'framework7-svelte';
// inputs
export let _name;
export let _inline = false;
export let _link = undefined;
export let _interactive = false;
export let _cssVariablePrefix = undefined;
export let _position = 'static';
// outputs
export let _clientWidthPx = null;
export let _clientHeightPx = null;
export let _clientWidthRem = null;
export let _clientHeightRem = null;
export let _borderBoxWidthPx = null;
export let _borderBoxHeightPx = null;
export let _borderBoxWidthRem = null;
export let _borderBoxHeightRem = null;
export let _contentBoxWidthPx = null;
export let _contentBoxHeightPx = null;
export let _contentBoxWidthRem = null;
export let _contentBoxHeightRem = null;
// consumed style props
export let background = undefined;
export let border = undefined;
export let centerContentHorizontally = undefined;
export let centerContentVertically = undefined;
export let fillHeight = undefined;
export let fillWidth = undefined;
export let filter = undefined;
export let overflow = undefined;
export let height = undefined;
export let hidden = undefined;
export let margin = undefined;
export let padding = undefined;
export let themeMode = undefined;
export let width = undefined;
export let boxShadow = undefined;
export let actions = undefined;
export let aspectRatio = undefined;
export let hideOnChildrenPages = undefined;
export let hideOnChildrenPagesVisibility = false;
const childrenPages = [
    'academy', 'safeguarding', 'leicester-city-in-the-community',
    'matchday-experiences', 'vs-foundation', 'profile',
    'player-registration', 'medical-provision', 'education', 'phase-1-fa-weetabix-wildcats-girls-football-programme-ages-5-11',
    'phase-2-schoolgirl-programme-under-11s-to-under-16s',
    'u18-', '-u18', 'u18s-', '-u18s', 'u21-', '-u21', 'u21s-', '-u21s'
];
function isHidden(_1, _2) {
    return hidden || (hideOnChildrenPages && !!childrenPages.find((slug) => window.location.href.includes(slug)));
}
$: _hidden = isHidden(hidden, hideOnChildrenPages);
let registeredForRouteChangeEvents = false;
$: if (hideOnChildrenPages && !registeredForRouteChangeEvents) {
    registeredForRouteChangeEvents = true;
    f7ready(() => {
        f7.view.main.router.on('routeChange', (newRoute, prevRoute) => {
            _hidden = isHidden(hidden, hideOnChildrenPages);
        });
    });
}
const tabContext = getContext(CardsTabContextKey);
// when bind:borderBoxSize and bind:contentBoxSize are working (Svelte 5?) then we should move to that.
//let _borderBoxSize: any, _contentBoxSize: any;
function handleResize({ borderBoxSize, contentBoxSize }) {
    if (!(borderBoxSize === null || borderBoxSize === void 0 ? void 0 : borderBoxSize.length) || !contentBoxSize.length) {
        return;
    }
    //console.log('handleResize', borderBoxSize, contentBoxSize);
    _clientWidthPx = _borderBoxWidthPx = borderBoxSize[0].inlineSize;
    _clientHeightPx = _borderBoxHeightPx = borderBoxSize[0].blockSize;
    _contentBoxWidthPx = contentBoxSize[0].inlineSize;
    _contentBoxHeightPx = contentBoxSize[0].blockSize;
    const pxPerRem = parseFloat(getComputedStyle(document.documentElement).fontSize);
    _clientWidthRem = _borderBoxWidthRem = _borderBoxWidthPx / pxPerRem;
    _clientHeightRem = _borderBoxHeightRem = _borderBoxHeightPx / pxPerRem;
    _contentBoxWidthRem = _contentBoxWidthPx / pxPerRem;
    _contentBoxHeightRem = _contentBoxHeightPx / pxPerRem;
}
//$: handleResize({borderBoxSize: _borderBoxSize, contentBoxSize: _contentBoxSize});
$: useHighlightColor = (background === null || background === void 0 ? void 0 : background.useHighlightColor) || false;
//$: inline = _inline && (!fillWidth && !fillHeight);
let modifiedBackground = background;
$: if (background) {
    try {
        modifiedBackground = _.cloneDeep(background);
        //modifiedBackground.image = xlator({  });
        modifiedBackground.image = rewriteExternalCDNURL(modifiedBackground.image);
        if (_clientWidthPx && _clientHeightPx) {
            const xfSlug = getImageTransformationSlug('background', _clientWidthPx, _clientHeightPx);
            if (xfSlug) {
                modifiedBackground.image = applyImageTransformation(modifiedBackground.image, xfSlug);
            }
        }
    }
    catch (e) {
        console.warn('error modifying background image URL', e);
    }
}
// extended style props passed as $$restProps
$: inlineStyle = mergeInlineStyles(buildInlineStyleFromObject($$restProps, _cssVariablePrefix), buildThemeModeInlineStyle(themeMode), buildInlineStyleFromObjects([modifiedBackground, 'particle-container-background'], [border, 'particle-container-border'], [filter, 'particle-container-filter'], [overflow, 'particle-container-overflow'], [height, 'particle-container-height'], [margin, 'particle-container-margin'], [padding, 'particle-container-padding'], [width, 'particle-container-width'], [boxShadow, 'particle-container-box-shadow'], [aspectRatio, 'particle-container-aspect-ratio']), `position: ${_position};`);
</script>

<style>
  /* remove default anchor and button styling */
  a, button {
    background: none;
    color: inherit;
    border: none;
    padding: 0;
    font: inherit;
    cursor: pointer;
    outline: inherit;
  }

  .yinzcam-particle-base {
    --yinzcam-inside-particle: true;
  }

  .yinzcam-particle-block {
    display: block;
  }

  /* NOTE: centering and fill only apply to block containers */

  .yinzcam-particle-block.particle-with-center-content-horizontally,
  .yinzcam-particle-block.particle-with-center-content-vertically {
    display: flex;
  }

  .yinzcam-particle-block.particle-with-center-content-horizontally {
    justify-content: center;
  }

  .yinzcam-particle-block.particle-with-center-content-vertically {
    align-items: center;
  }

  .yinzcam-particle-base.particle-with-fill-width:not(.particle-with-width) {
    width: 100%;
  }

  .yinzcam-particle-base.particle-with-fill-height:not(.particle-with-height) {
    height: 100%;
  }

  .yinzcam-particle-base.particle-with-width {
    width: var(--particle-container-width);
  }

  .yinzcam-particle-base.particle-with-height {
    height: var(--particle-container-height);
  }

  .yinzcam-particle-base.particle-with-aspect-ratio {
    aspect-ratio: var(--particle-container-aspect-ratio);
  }

  .yinzcam-particle-inline {
    display: inline;
  }

  .yinzcam-particle-inline.particle-with-height,
  .yinzcam-particle-inline.particle-with-width,
  .yinzcam-particle-inline.particle-with-padding {
    display: inline-block;
  }

  /*
  .yinzcam-particle-inline.particle-with-center-content-horizontally {
    text-align: center;
  }

  .yinzcam-particle-inline.particle-with-center-content-vertically {
    vertical-align: middle;
  }
  */

  .yinzcam-particle-base.particle-with-background {
    background-image: var(--particle-container-background-image, unset);
    background-color: var(--particle-container-background-color, var(--theme-mode-background-color, unset));
    background-repeat: var(--particle-container-background-repeat, unset);
    background-size: var(--particle-container-background-size, unset);
    background-attachment: var(--particle-container-background-attachment, unset);
    background-position-x: var(--particle-container-background-horizontal-alignment, unset);
    background-position-y: var(--particle-container-background-vertical-alignment, unset);
    background-clip: var(--particle-container-background-clip, unset);
    -webkit-backdrop-filter: var(--particle-container-background-filter, unset);
            backdrop-filter: var(--particle-container-background-filter, unset);
  }

  .yinzcam-particle-base.particle-with-background.use-highlight-color {
    background-color: var(--particle-container-background-color, var(--theme-mode-highlight-color, unset));
  }

  @supports (background-color: lch(from white l c h)) {
    .yinzcam-particle-base.particle-with-background.background-with-lightness-modifier {
      background-color: lch(from var(--particle-container-background-color, var(--theme-mode-background-color, transparent)) calc(l * (var(--particle-container-background-color-lightness, 100)/100)) c h);
    }

@supports (color: lab(from red l 1 1% / calc(alpha + 0.1))) {
.yinzcam-particle-base.particle-with-background.background-with-lightness-modifier {
      background-color: lch(from var(--particle-container-background-color, var(--theme-mode-background-color, transparent)) calc(l * calc(var(--particle-container-background-color-lightness, 100)/100)) c h);
    }
}

    .yinzcam-particle-base.particle-with-background.use-highlight-color {
      background-color: lch(from var(--particle-container-background-color, var(--theme-mode-highlight-color, transparent)) calc(l * (var(--particle-container-background-color-lightness, 100)/100)) c h);
    }

@supports (color: lab(from red l 1 1% / calc(alpha + 0.1))) {
.yinzcam-particle-base.particle-with-background.use-highlight-color {
      background-color: lch(from var(--particle-container-background-color, var(--theme-mode-highlight-color, transparent)) calc(l * calc(var(--particle-container-background-color-lightness, 100)/100)) c h);
    }
}
  }

  .yinzcam-particle-base.particle-with-border {
    border-style: var(--particle-container-border-style, unset);
    border-color: var(--particle-container-border-color, var(--theme-mode-foreground-color, unset));
    border-width: var(--particle-container-border-width, unset);
    border-radius: var(--particle-container-border-radius, unset);
  }

  .yinzcam-particle-base.particle-with-filter {
    -webkit-filter: var(--particle-container-filter, unset);
            filter: var(--particle-container-filter, unset);
  }

  .yinzcam-particle-base.particle-with-overflow{
    overflow: var(--particle-container-overflow, unset)
  }

  .yinzcam-particle-base.particle-with-hidden {
    display: none;
  }

  .yinzcam-particle-base.particle-with-margin {
    margin: var(--particle-container-margin, unset);
  }

  .yinzcam-particle-base.particle-with-padding {
    padding: var(--particle-container-padding, unset);
  }

  .yinzcam-particle-base.particle-with-box-shadow {
    box-shadow: var(--particle-container-box-shadow, unset);
  }

  .yinzcam-particle-base :global(*),
  .yinzcam-particle-base :global(*::before),
  .yinzcam-particle-base :global(*::after) {
    box-sizing: border-box;
  }

  .yinzcam-particle-base.particle-hidden-display {
    display: none;
  }

  .yinzcam-particle-base.particle-hidden-visibility {
    visibility: hidden;
  }</style>

<Boundary>
{#if _link}
<a
  use:resizeObserverAction={handleResize}
  role="button"
  data-yinzcam-particle-name={_name}
  class:yinzcam-particle-base={true}
  class:yinzcam-particle-inline={_inline}
  class:yinzcam-particle-block={!_inline}
  class:particle-with-background={background && background.visible}
  class:background-with-lightness-modifier={background && background.colorLightness}
  class:particle-with-border={border && border.visible}
  class:particle-with-center-content-horizontally={centerContentHorizontally}
  class:particle-with-center-content-vertically={centerContentVertically}
  class:particle-with-fill-height={!_inline && fillHeight}
  class:particle-with-fill-width={!_inline && fillWidth}
  class:particle-with-filter={filter}
  class:particle-with-overflow={overflow}
  class:particle-with-height={height}
  class:particle-with-hidden={hidden}
  class:particle-with-margin={margin}
  class:particle-with-padding={padding}
  class:particle-with-width={width}
  class:particle-with-box-shadow={boxShadow}
  class:particle-with-aspect-ratio={aspectRatio}
  class:use-highlight-color={useHighlightColor}
  class:particle-hidden-display={_hidden && !hideOnChildrenPagesVisibility}
  class:particle-hidden-visibility={_hidden && hideOnChildrenPagesVisibility}
  class:external={isAbsoluteUrl(_link)}
  target={(shouldOpenInNewWindow(_link))? '_blank' : '_self'}
  href={_link}
  style={inlineStyle}
  on:click
  on:mouseenter
  on:mouseleave
  on:touchstart
  on:mouseover
  on:focus>
  <slot></slot>
</a>
{:else if _interactive}
<button
  use:resizeObserverAction={handleResize}
  data-yinzcam-particle-name={_name}
  class:yinzcam-particle-base={true}
  class:yinzcam-particle-inline={_inline}
  class:yinzcam-particle-block={!_inline}
  class:particle-with-background={background && background.visible}
  class:background-with-lightness-modifier={background && background.colorLightness}
  class:particle-with-border={border && border.visible}
  class:particle-with-center-content-horizontally={centerContentHorizontally}
  class:particle-with-center-content-vertically={centerContentVertically}
  class:particle-with-fill-height={!_inline && fillHeight}
  class:particle-with-fill-width={!_inline && fillWidth}
  class:particle-with-filter={filter}
  class:particle-with-overflow={overflow}
  class:particle-with-height={height}
  class:particle-with-hidden={hidden}
  class:particle-with-margin={margin}
  class:particle-with-padding={padding}
  class:particle-with-width={width}
  class:particle-with-box-shadow={boxShadow}
  class:particle-with-aspect-ratio={aspectRatio}
  class:use-highlight-color={useHighlightColor}
  class:particle-hidden-display={_hidden && !hideOnChildrenPagesVisibility}
  class:particle-hidden-visibility={_hidden && hideOnChildrenPagesVisibility}
  style={inlineStyle}
  on:click
  on:mouseenter
  on:mouseleave
  on:touchstart
  on:mouseover
  on:focus
  >
  <slot></slot>
</button>
{:else}
{#if _inline}
<span
  use:resizeObserverAction={handleResize}
  use:containerActionsHandler={{ actions, tabContext }}
  data-yinzcam-particle-name={_name}
  class:yinzcam-particle-base={true}
  class:yinzcam-particle-inline={true}
  class:particle-with-background={background && background.visible}
  class:background-with-lightness-modifier={background && background.colorLightness}
  class:particle-with-border={border && border.visible}
  class:particle-with-center-content-horizontally={centerContentHorizontally}
  class:particle-with-center-content-vertically={centerContentVertically}
  class:particle-with-filter={filter}
  class:particle-with-overflow={overflow}
  class:particle-with-height={height}
  class:particle-with-hidden={hidden}
  class:particle-with-margin={margin}
  class:particle-with-padding={padding}
  class:particle-with-width={width}
  class:particle-with-box-shadow={boxShadow}
  class:particle-with-aspect-ratio={aspectRatio}
  class:use-highlight-color={useHighlightColor}
  class:particle-hidden-display={_hidden && !hideOnChildrenPagesVisibility}
  class:particle-hidden-visibility={_hidden && hideOnChildrenPagesVisibility}
  style={inlineStyle}>
  <slot></slot>
</span>
{:else}
<div
  use:resizeObserverAction={handleResize}
  use:containerActionsHandler={{ actions, tabContext }}
  data-yinzcam-particle-name={_name}
  class:yinzcam-particle-base={true}
  class:yinzcam-particle-block={true}
  class:particle-with-background={background && background.visible}
  class:background-with-lightness-modifier={background && background.colorLightness}
  class:particle-with-border={border && border.visible}
  class:particle-with-center-content-horizontally={centerContentHorizontally}
  class:particle-with-center-content-vertically={centerContentVertically}
  class:particle-with-fill-height={fillHeight}
  class:particle-with-fill-width={fillWidth}
  class:particle-with-filter={filter}
  class:particle-with-overflow={overflow}
  class:particle-with-height={height}
  class:particle-with-hidden={hidden}
  class:particle-with-margin={margin}
  class:particle-with-padding={padding}
  class:particle-with-width={width}
  class:particle-with-box-shadow={boxShadow}
  class:particle-with-aspect-ratio={aspectRatio}
  class:use-highlight-color={useHighlightColor}
  class:particle-hidden-display={_hidden && !hideOnChildrenPagesVisibility}
  class:particle-hidden-visibility={_hidden && hideOnChildrenPagesVisibility}
  style={inlineStyle}>
  <slot></slot>
</div>
{/if}
{/if}
</Boundary>
