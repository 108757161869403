<script lang="ts">import { writable } from "svelte/store";
import "js/i18n";
import { applyImageTransformation, getImageTransformationSlug, getTranslator, imageAspectRatioAction, rewriteExternalCDNURL, } from "components/cards/utilities";
import { BaseParticle, } from "components/cards/particles";
import { resizeObserverAction } from "components/cards/common/actions";
import "./spec";
// props
export let container = undefined;
export let maxHeight = undefined;
export let maxWidth = undefined;
export let fillHeight = undefined;
export let fillWidth = undefined;
export let source = "";
export let icon = "";
export let iconColor = undefined;
export let languageFlag = "";
export let alt = "";
export let translations = [];
export let aspectRatio = undefined;
export let sizing = undefined;
export let fitHorizontalAlign = undefined;
export let fitVerticalAlign = undefined;
export let hoverZoomEnabled = false;
export let darkenFilterEnabled = false;
export let role = undefined;
export let handleImageError = undefined;
// inputs
export let _block = false;
export let _hovering = false; // external hover state
// outputs
export let _sourceAspectRatio = null;
$: _hoverZoomEnabled = !CONFIG.disableHoverZoom && hoverZoomEnabled;
const _sourceAspectRatioStore = writable();
$: {
    _sourceAspectRatio = $_sourceAspectRatioStore;
}
$: xlator = getTranslator(translations, { source, alt });
let imageContainerWidthPx, imageContainerHeightPx;
function handleImageResize({ borderBoxSize, contentBoxSize }) {
    if (!(borderBoxSize === null || borderBoxSize === void 0 ? void 0 : borderBoxSize.length)) {
        return;
    }
    imageContainerWidthPx = borderBoxSize[0].inlineSize;
    imageContainerHeightPx = borderBoxSize[0].blockSize;
}
let modifiedSource = source;
$: if (source && !icon) {
    try {
        modifiedSource = xlator({ source });
        modifiedSource = rewriteExternalCDNURL(modifiedSource);
        if (role && imageContainerWidthPx && imageContainerHeightPx) {
            const xfSlug = getImageTransformationSlug(role, imageContainerWidthPx, imageContainerHeightPx);
            if (xfSlug) {
                modifiedSource = applyImageTransformation(modifiedSource, xfSlug);
            }
        }
    }
    catch (e) {
        console.warn("error modifying image source URL", e);
    }
}
let options = {
    root: null,
    rootMargin: "0px",
    threshold: 0.1,
};
const lazyLoad = (image, src, options = undefined) => {
    const loaded = () => {
        image.classList.add("visible");
        image.style.opacity = 1;
    };
    const observer = new IntersectionObserver((entries) => {
        entries.forEach((entry) => {
            if (entry.isIntersecting) {
                image.src = src;
                if (image.complete) {
                    loaded();
                }
                else {
                    image.addEventListener("load", loaded, { once: true });
                }
                observer.unobserve(image);
            }
        });
    }, options);
    observer.observe(image);
    const update = (newImageSrc) => {
        src = newImageSrc;
        observer.disconnect();
        observer.observe(image);
        if (image.complete) {
            loaded();
        }
        else {
            image.addEventListener("load", loaded, { once: true });
        }
    };
    return {
        update,
        destroy() {
            image.removeEventListener("load", loaded);
            observer.unobserve(image);
        },
    };
};
</script>

<BaseParticle
  _name="ImageParticle"
  _cssVariablePrefix="image-particle"
  _inline={!_block}
  {...container}
  {aspectRatio}
  objectFit={sizing}
  {fitHorizontalAlign}
  {fitVerticalAlign}
  {iconColor}
  {maxHeight}
  {maxWidth}
>
  {#if icon && !source}
    <svg
      viewBox="0 0 32 32"
      class:display-block-enabled={_block}
      class:hover-zoom-enabled={_hoverZoomEnabled}
      class:hovering={_hovering}
      class:darken-filter-enabled={darkenFilterEnabled}
      class:fill-height-enabled={fillHeight}
      class:fill-width-enabled={fillWidth}
    >
      <use xlink:href={`#${icon}`}></use>
    </svg>
  {:else if languageFlag && !source}
    <span
      use:resizeObserverAction={handleImageResize}
      class:display-block-enabled={_block}
      class:hover-zoom-enabled={_hoverZoomEnabled}
      class:hovering={_hovering}
      class:darken-filter-enabled={darkenFilterEnabled}
      class:fill-height-enabled={fillHeight}
      class:fill-width-enabled={fillWidth}
      class={`lang-icon lang-icon-${languageFlag}`}
    />
  {:else if modifiedSource || source}
    <img
      use:resizeObserverAction={handleImageResize}
      use:lazyLoad={modifiedSource || source}
      alt={xlator({ alt })}
      class:display-block-enabled={_block}
      class:hover-zoom-enabled={_hoverZoomEnabled}
      class:hovering={_hovering}
      class:darken-filter-enabled={darkenFilterEnabled}
      class:fill-height-enabled={fillHeight}
      class:fill-width-enabled={fillWidth}
      use:imageAspectRatioAction={_sourceAspectRatioStore}
      on:error={handleImageError}
      src=""
    />
  {:else}
    <span class:display-block-enabled={_block} />
  {/if}
</BaseParticle>

<style>
  img,
  svg {
    max-width: var(--image-particle-max-width, 100%);
    max-height: var(--image-particle-max-height, 100%);
    aspect-ratio: var(--image-particle-aspect-ratio, auto 16/9);
  }

  svg {
    fill: var(
      --image-particle-icon-color,
      var(--theme-mode-foreground-color, inherit)
    );
  }

  .display-block-enabled {
    display: block;
    object-fit: var(--image-particle-object-fit, unset);
    object-position: var(--image-particle-fit-horizontal-align, unset)
      var(--image-particle-fit-vertical-align, unset);
  }

  .display-block-enabled.fill-height-enabled {
    height: 100%;
  }

  .display-block-enabled.fill-width-enabled {
    width: 100%;
  }

  .darken-filter-enabled {
    -webkit-filter: brightness(80%);
            filter: brightness(80%);
  }

  .hover-zoom-enabled {
    transition: transform 1s ease;
  }

  .hover-zoom-enabled.hovering {
    transform: scale(1.1);
  }</style>
