<script context="module" lang="ts">export { specification } from "./spec";
</script>

<script lang="ts">import { merge } from "lodash";
import { DateTime } from "luxon";
import "components/cards/utilities";
import { BaseAtom } from "components/cards/atoms";
import { TextParticle, ImageParticle, ButtonParticle, } from "components/cards/particles/YinzCam";
import { writable } from "svelte/store";
import { onMount } from "svelte";
const hovering = writable(false);
const formatDateTime = (dateString) => {
    var _a;
    const dt = DateTime.fromISO(dateString, { zone: "Europe/London" });
    const convertedDateTime = (_a = dt
        .toLocaleString(Object.assign(Object.assign({ weekday: "long", year: "numeric", month: "long", day: "numeric", hour: "2-digit", minute: "2-digit" }, DateTime.DATE_FULL), DateTime.TIME_24_SIMPLE))) === null || _a === void 0 ? void 0 : _a.split(" ");
    return convertedDateTime === null || convertedDateTime === void 0 ? void 0 : convertedDateTime.map((string) => string.trim().replace(",", ""));
};
const computeCountdown = (dateString) => {
    var _a;
    let matchDate = DateTime.fromISO(dateString);
    let values = (_a = matchDate.diff(DateTime.now(), [
        "weeks",
        "days",
        "hours",
        "minutes",
    ])) === null || _a === void 0 ? void 0 : _a.values;
    [ctWeeks, ctDays, ctHours, ctMins] = [
        values === null || values === void 0 ? void 0 : values.weeks,
        values === null || values === void 0 ? void 0 : values.days,
        values === null || values === void 0 ? void 0 : values.hours,
        values === null || values === void 0 ? void 0 : values.minutes,
    ];
};
export let container;
export let overlayPadding;
export let backgroundColor;
export let imgLeaguelogo;
export let matchDateTime;
export let txtMatchDate;
export let txtMatchTime;
export let txtStadiumName;
export let imgBroadcastPartner;
export let txtHomeTeam;
export let txtAwayTeam;
export let imgHomeTeam;
export let imgAwayTeam;
export let btnCTA;
export let matchStatus;
export let homeTeamScore;
export let awayTeamScore;
export let matchMinutes;
export let isChannelImageRequired;
export let isPenalty;
export let txtLive;
export let txtLiveSmall;
export let colorLiveIconBackground;
export let colorLiveIconBorder;
export let txtPenalties;
export let txtAwayTeamAbbr;
export let txtHomeTeamAbbr;
export let colorFirstButton;
export let colorLiveIconCircle;
export let txtCountDownTime;
export let txtCountDownText;
export let countdownBoxColor;
export let isCountdownRequired;
let atomWidthRem;
$: isMobile = atomWidthRem <= 40;
$: isTablet = atomWidthRem <= 64 && atomWidthRem > 40;
$: leagueIconMultiplier = isMobile ? "0.71" : "1.00";
$: dateTextMultiplier = isMobile ? "0.66" : "1.00";
$: timeTextMultiplier = isMobile ? "0.56" : "1.00";
$: timeZoneTextMultiplier = isMobile ? "0.34" : "0.44";
$: stadiumNameTextMultiplier = isMobile ? "0.75" : "1.00";
$: teamTextMultiplier = isMobile ? "0.66" : "1.00";
$: teamIconMultiplier = isMobile ? "0.73" : isTablet ? "1.18" : "1.00";
$: btnTextMultiplier = isMobile ? "0.70" : "1.00";
$: firstButtonProps = (btnCTA === null || btnCTA === void 0 ? void 0 : btnCTA.length) && btnCTA[0];
$: remainingButtons = (btnCTA === null || btnCTA === void 0 ? void 0 : btnCTA.length) && (btnCTA === null || btnCTA === void 0 ? void 0 : btnCTA.slice(1));
$: scoreTextMultiplier = isMobile ? "0.58" : "1.00";
$: matchMinsTextMultiplier = isMobile ? "0.56" : "1.00";
$: themeModeValue = matchStatus === "live" ? "inverted" : "primary";
$: txtPenaltiesMultiplier = isMobile ? "0.5" : "1.00";
$: ctaSizeMultiplier = isMobile ? "0.6" : "1.00";
$: ctWeeks = "";
$: ctDays = "";
$: ctHours = "";
$: ctMins = "";
$: headerText =
    matchStatus === "live"
        ? "LIVE"
        : matchStatus === "post"
            ? "RESULT"
            : "NEXT UP";
$: [day, date, month, year, , time, timezone] =
    formatDateTime(matchDateTime);
$: computeCountdown(matchDateTime);
$: countDownID = setInterval(() => computeCountdown(matchDateTime), 60000);
onMount(() => {
    return () => clearInterval(countDownID);
});
</script>

<BaseAtom
    _isotope="FixturesStackedAtom"
    {...container}
    _cssVariablePrefix="fix-stack"
    {overlayPadding}
    {backgroundColor}
    {colorLiveIconBackground}
    {colorLiveIconBorder}
    {colorFirstButton}
    {colorLiveIconCircle}
    {countdownBoxColor}
    bind:_clientWidthRem={atomWidthRem}
>
    <div
        class="main-container"
        class:layout-mobile-width={isMobile}
        class:layout-tablet-width={isTablet}
        class:live-match={matchStatus === "live"}
    >
        <div class="content-box">
            <div class="header-text">
                <TextParticle {...txtLive} text={headerText} />
            </div>
            <div class="inner-content">
                <div class="league-icon">
                    <ImageParticle {...imgLeaguelogo} />
                </div>
                <div class="match-date">
                    <TextParticle
                        {...txtMatchDate}
                        text={isMobile
                            ? `${day?.slice(0, 3)} ${date} ${month?.slice(0, 3)}, ${year}`
                            : `${day} ${date} ${month}, ${year}`}
                    />
                </div>
                <div class="match-details">
                    <ImageParticle {...imgHomeTeam} />
                    <div
                        class="center-text"
                        class:apply-shade={["pre", "post"].includes(
                            matchStatus,
                        )}
                    >
                        {#if matchStatus === "pre"}
                            <div class="pre-status-content">
                                <TextParticle {...txtMatchTime} text={time} />
                            </div>
                        {/if}
                        {#if matchStatus === "post"}
                            <div
                                class="post-status-content"
                                class:mobile={isMobile}
                            >
                                <div class="home-goals">
                                    <TextParticle {...homeTeamScore} />
                                </div>
                                <span>-</span>
                                <div class="away-goals">
                                    <TextParticle {...awayTeamScore} />
                                </div>
                            </div>
                        {/if}
                        {#if matchStatus === "live"}
                            <div class="live-status-content">
                                <div class="home-goals">
                                    <TextParticle {...homeTeamScore} />
                                </div>
                                <div class="live-minutes">
                                    <div class="minutes">
                                        <TextParticle {...matchMinutes} />
                                    </div>
                                    <div class="live-text">
                                        <TextParticle
                                            {...txtLiveSmall}
                                            text={"LIVE"}
                                        />
                                    </div>
                                </div>
                                <div class="away-goals">
                                    <TextParticle {...awayTeamScore} />
                                </div>
                            </div>
                        {/if}
                    </div>
                    <ImageParticle {...imgAwayTeam} />
                </div>

                <div class="stadium-name">
                    <TextParticle {...txtStadiumName} />
                </div>
            </div>
            <div class="cta-buttons">
                {#each btnCTA || [] as button, index}
                    <div class="button-alone">
                        <ButtonParticle
                            {...merge({}, button, {
                                container: {
                                    background: {
                                        color:
                                            btnCTA.length > 1 &&
                                            index === btnCTA.length - 1
                                                ? "var(--fix-stack-background-color, #d0d0d0)"
                                                : "var(--theme-mode-background-color, #164194)",
                                    },
                                },
                                text: {
                                    color:
                                        btnCTA.length > 1 &&
                                        index === btnCTA.length - 1
                                            ? "var(--theme-mode-foreground-color, #000)"
                                            : "var(--theme-mode-background-color, #FFF)",
                                },
                            })}
                        />
                    </div>
                {/each}
            </div>
        </div>
        {#if isCountdownRequired}
            <div class="countdown-container">
                {#if matchStatus === "pre"}
                    <div class="countdown-box">
                        <div class="weeks">
                            <TextParticle
                                {...txtCountDownTime}
                                text={`${ctWeeks}`}
                            />
                            <TextParticle {...txtCountDownText} text="WEEKS" />
                        </div>
                        <div class="days">
                            <TextParticle
                                {...txtCountDownTime}
                                text={`${ctDays}`}
                            />
                            <TextParticle {...txtCountDownText} text="DAYS" />
                        </div>
                        <div class="hours">
                            <TextParticle
                                {...txtCountDownTime}
                                text={`${ctHours}`}
                            />
                            <TextParticle {...txtCountDownText} text="HOURS" />
                        </div>
                        <div class="mins">
                            <TextParticle
                                {...txtCountDownTime}
                                text={`${parseInt(ctMins).toFixed(0)}`}
                            />
                            <TextParticle {...txtCountDownText} text="MINS" />
                        </div>
                    </div>
                {/if}
            </div>
        {/if}
    </div>
</BaseAtom>

<style>
    .main-container {
        width: 100%;
        height: 100%;
        overflow: hidden;
    }
    .content-box {
        width: 100%;
        height: 100%;
        padding: var(--fix-stack-overlay-padding);
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: space-evenly;
    }
    .header-text {
        width: 100%;
        background-color: var(
            --fix-stack-color-live-icon-background,
            var(--theme-mode-background-color, #164194)
        );
        text-align: center;
        padding: 0.5rem;
        border-radius: 2.5rem;
        color: var(--theme-mode-foreground-color, #fff);
    }
    .league-icon{
        padding: 0.5rem 0;
    }
    .inner-content {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: start;
    }
    .match-details {
        width: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
    }
    .match-details .center-text {
            width: 10rem;
        }
    .match-details .center-text.apply-shade {
            background-color: var(--fix-stack-background-color, #d0d0d0);
            border-radius: 0.25rem;
        }
    .cta-buttons {
        width: 100%;
        display: flex;
        justify-content: space-between;
        overflow: hidden;
        border-radius: 2.5rem;
    }
    .cta-buttons > * {
        flex-grow: 1;
    }
    .live-status-content {
        display: flex;
        justify-content: space-evenly;
        align-items: center;
    }
    .live-status-content .live-minutes {
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;
        }
    .post-status-content {
        display: flex;
        justify-content: space-evenly;
        align-items: center;
        padding: 0.5rem 1rem;
    }
    .post-status-content span {
            font-size: 2.25rem;
            font-weight: 800;
            color: #164194;
        }
    .post-status-content.mobile span {
            font-size: 1.5rem;
        }
    .pre-status-content {
        padding: 0.5rem 1rem;
        text-align: center;
    }
    .match-date {
        display: flex;
        flex-direction: column;
        align-items: center;
        gap: 0.25rem;
    }
    .countdown-box {
        width: 100%;
        display: flex;
        justify-content: space-around;
        background-color: var(--fix-stack-countdown-box-color, #d0d0d0);
        padding: 0.5rem;
    }
    .countdown-box .weeks,
        .countdown-box .days,
        .countdown-box .hours,
        .countdown-box .mins {
            display: flex;
            flex-direction: column;
            align-items: center;
        }
    .countdown-container {
        width: 100%;
        margin-top: 1rem;
    }
    .layout-mobile-width .stadium-name,.layout-tablet-width .stadium-name{
            padding: 0.5rem 0;
        }
    .stadium-name{
            padding: 0.5rem 0;
        }</style>
