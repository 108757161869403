import { generateBooleanSchema, generateRootObjectSchema, generateStringSchema, generateUrlSchema, generateDateTimeSchema, generateArraySchema, generateDurationSchema, generateNumberSchema } from "components/cards/common/schema";
import { BaseAtomSpec, generateAtomContainerSchema } from "components/cards/atoms/BaseAtomSpec";
import { TextParticleSpec, ImageParticleSpec, ButtonParticleSpec } from "components/cards/particles/YinzCam";

export const specification = new BaseAtomSpec("Regular Horizontal Card", generateRootObjectSchema({
    container: generateAtomContainerSchema(),
    overlayPadding: generateStringSchema("Overlay Padding", "The padding (inset) of the overlay text/buttons relative to the background image.", {
        defaultValue: '3rem'
    }),
    mediaType: generateStringSchema("Type", "The type of this media item. This controls the media-type indicator icon as well as the default media item deep link.", {
        choices: ['article', 'video', 'gallery', 'audio'],
        choiceTitles: ["News Article", "Video", "Photo Gallery", "Audio"]
    }),
    slug: generateStringSchema("Slug", "The slug for this media item. This will be used to generate the media item link if an explicit link is not provided."),
    link: generateUrlSchema("Link", "The link for this media item. The default link will be generated from the slug if this is not provided (and the slug is)."),
    publishDateTime: generateDateTimeSchema("Publish Date and Time", "The date and time that this content was published."),
    viewTimeDuration: generateDurationSchema("View Time Duration", "The duration that an average user would take to completely consume this content."),
    views: generateNumberSchema("Views", "The number of views of this content."),
    thumbnail: ImageParticleSpec.generateSchema("Image Media", "Upload Image file", {
        defaultValues: { sizing: "cover", fitVerticalAlign: 'top', role: 'general' }
    }),
    titleText: TextParticleSpec.generateSchema("Title Text", "Enter title text", {
        defaultValues: { size: "2.5rem", weight: "900", lineClamp: 3 }
    }),
    descriptionText: TextParticleSpec.generateSchema("Description text", "Enter a description text", {
        defaultValues: { size: "1.25rem", weight: "400", lineClamp: 3 }
    }),
    imgClockIcon: ImageParticleSpec.generateSchema("Clock Icon", "Select/upload an icon for clock", {
        defaultValues: { container: { width: "1.25rem", height: "1.25rem" } }
    }),
    txtDateTime: TextParticleSpec.generateSchema("Date Time Text", "The text styling for the date/time text.", {
        defaultValues: { size: '1rem', weight: '700' },
        uneditableFields: { text: true }
    }),
    imgShareIcon: ImageParticleSpec.generateSchema("Share Icon", "Select/upload an icon for share option", {
        defaultValues: { container: { width: "2rem", height: "2rem" } }
    }),
    shouldDisplayBodyText: generateBooleanSchema("Should display body text?", "Select if we should display body text", {
        defaultValue: true,
    }),
    reverseOrientation: generateStringSchema("Content Orientation", "Specify orientation of content", {
        choices: ["image-first", "text-first"],
        choiceTitles: ["Image First", "Text First"],
        defaultValue: "image-first"
    }),
    displayDateDuration: generateStringSchema("Display Date or Duration", "Select if to display date or duration", {
        choices: ["date", "duration"],
        choiceTitles: ["Date", "Duration"],
        defaultValue: "date",
    }),
    shouldDisplayTime: generateBooleanSchema("Should Display Time?", "Should Display Time?", {
        defaultValue: true
    }),
    shouldDisplayDuration: generateBooleanSchema("Should Display Duration?", "Should Display Duration?", {
        defaultValue: false
    }),
    shouldDisplayViews: generateBooleanSchema("Should Display Views?", "Should Display Views?", {
        defaultValue: false
    }),
    textBoxWidth: generateStringSchema("Text Box Width", "Text Box Width %"),
    categories: generateArraySchema("Categories", "The categories to list for the media item.",
        ButtonParticleSpec.generateSchema("Category", "A category for the media item.", {
            defaultValues: {
                showImage: false, container: { themeMode: 'inverted' }, text: { size: "1.25rem", weight: "700" }
            }
        })
    ),
    shouldDisplayCategories: generateBooleanSchema("Should display categories?", "Specify if categories should be displayed"),
}));

